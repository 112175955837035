import React, { useEffect, useState, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import axios from 'axios';
import Titulo from "../assets/prognostico.png";
import 'mapbox-gl/dist/mapbox-gl.css';

const Prognostico = () => {
  const [userLocation, setUserLocation] = useState({ latitude: 0, longitude: 0 });
  const [weatherData, setWeatherData] = useState({
    temperature: 0,
    humidity: 0,
    rainProbability: 0,
  });
  const [mosquitoProbability, setMosquitoProbability] = useState(0);

  const mapContainer = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYmVsZW50b3VyZXR0ZSIsImEiOiJjbG51ZXAzNzkwYTlpMm10M2hzZTdlcXU5In0.hry6723KR8jRRuSOkUxurw'; 

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [-24, 42],
      zoom: 1,
    });

    const geolocateControl = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showUserHeading: true,
    });

    map.addControl(geolocateControl);

    geolocateControl.on('geolocate', (e) => {
      const { coords } = e;
      setUserLocation({ latitude: coords.latitude, longitude: coords.longitude });
      fetchWeatherData(coords.latitude, coords.longitude);
    });

    // Clean up map on component unmount
    return () => {
      map.remove();
    };
  }, []);

  const fetchWeatherData = async (latitude, longitude) => {
    try {
      const apiKey = '68e6e466da2c333c4528b450ff680d19'; // Replace with your OpenWeatherMap API key
      const response = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=metric`
      );

      const { main } = response.data;
      setWeatherData({
        temperature: main.temp,
        humidity: main.humidity,
        rainProbability: response.data?.rain?.['1h'] || 0,
      });

      const mosquitoProbability = calculateMosquitoProbability(main.temp, main.humidity, response.data?.rain?.['1h'] || 0);
      setMosquitoProbability(mosquitoProbability);
    } catch (error) {
      console.error('Error fetching weather data:', error);
    }
  };

  const calculateMosquitoProbability = (temperature, humidity, rainProbability) => {
    const temperatureWeight = 0.4;
    const humidityWeight = 0.3;
    const rainWeight = 0.3;

    const normalizedTemperature = temperature / 40;
    const normalizedHumidity = humidity / 100;
    const normalizedRain = rainProbability / 100;

    const mosquitoProbability = (normalizedTemperature * temperatureWeight) +
      (normalizedHumidity * humidityWeight) +
      (normalizedRain * rainWeight);

    return mosquitoProbability * 100;
  };

  return (
    <div id="pronostico" className="section">
      <div>
        <div className='flex flex-col justify-center items-center gap-y-4 h-mt-10'>
          <img src={Titulo} alt="Prognostico" />
          <div className='sick-box flex flex-col justify-center items-center text-center rounded-xl py-4 px-4'>
            <p className='text-white font-bold text-lg'>Comparte tu ubicación con la aplicación y averigua cuál es la probabilidad de picaduras de mosquitos dónde te encuentras.</p>
            <p className='text-yellow-300 font-bold text-lg'>Las condiciones climáticas ideales para los mosquitos son temperaturas cálidas, alta humedad, temporada de lluvias, agua estancada y poca brisa. Estas condiciones favorecen su reproducción y actividad. La eliminación de criaderos de mosquitos, el uso de mosquiteros y repelentes, y otras medidas de control de mosquitos son esenciales para reducir la exposición a las picaduras y prevenir enfermedades transmitidas por mosquitos.</p>
          </div>
        </div>
        <div ref={mapContainer} className="mt-6 border-8 border-green-950" style={{ width: '70vw', height: '70vh' }} />
        <div className="mt-4 flex flex-row justify-center items-center gap-x-2">
          <div className='rounded-2xl flex flex-row justify-center items-center text-green-900'>
            <div className='bg-yellow-300 py-4 px-4 rounded-s-2xl'>
              <p className='text-sm uppercase font-bold'>Temperatura:</p>
            </div>
            <div className='bg-white py-4 px-4 rounded-e-2xl'>
              <p className='text-sm uppercase font-bold'>{weatherData.temperature}°C</p>
            </div>
          </div>
          <div className='rounded-2xl flex flex-row justify-center items-center text-green-900'>
            <div className='bg-yellow-300 py-4 px-4 rounded-s-2xl'>
              <p className='text-sm uppercase font-bold'>Humedad:</p>
            </div>
            <div className='bg-white py-4 px-4 rounded-e-2xl'>
              <p className='text-sm uppercase font-bold'>{weatherData.humidity}%</p>
            </div>
          </div>
          <div className='rounded-2xl flex flex-row justify-center items-center text-green-900'>
            <div className='bg-yellow-300 py-4 px-4 rounded-s-2xl'>
              <p className='text-sm uppercase font-bold'>Probabilidad de lluvia:</p>
            </div>
            <div className='bg-white py-4 px-4 rounded-e-2xl'>
              <p className='text-sm uppercase font-bold'>{weatherData.rainProbability}%</p>
            </div>
          </div>
          <div className='rounded-2xl flex flex-row justify-center items-center text-green-900'>
            <div className='bg-yellow-300 py-4 px-4 rounded-s-2xl'>
              <p className='text-sm uppercase font-bold'>Probabilidad de Mosquitos:</p>
            </div>
            <div className='bg-white py-4 px-4 rounded-e-2xl'>
              <p className='text-sm uppercase font-bold'>{mosquitoProbability.toFixed(2)}%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prognostico;
