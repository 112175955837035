import React, { useState, useEffect} from 'react';
import PedidosYa from "../assets/pedidosya.png"
import Farmacorp from "../assets/farmacorp.png"
import CallCenter from "../assets/call-center.png"
import Facebook from "../assets/facebook.png"
import Instagram from "../assets/instagram.png"
import Youtube from "../assets/youtube.png"
import Composicion from "../assets/composicion.png"
import Logo from "../assets/inti-footer.png"

function Footer() {
    //mobile view
    const [mobile, setMobile] = useState(null);
    const [windowWidth, setWindowWidth] = useState(null);
    const [inputValue, setInputValue] = useState(''); 
    const [isOpen, setIsOpen] = useState(false);
  
  
    const checkScreen = () => {
        setWindowWidth(window.innerWidth);
        setMobile(window.innerWidth <= 850);
        setIsOpen(false);
    };
  
    useEffect(() => {
        window.addEventListener('resize', checkScreen);
        checkScreen();
  
        return () => {
            window.removeEventListener('resize', checkScreen);
        };
    }, []);

  return (
    <div>
      {!mobile && (
      <div className='flex flex-col justify-center items-center'>
        <div style={{backgroundColor: "#004630"}} className='w-screen py-4 px-12'>
          <div className='text-white font-bold flex flex-row justify-between items-center'>
            <div className='flex flex-row justify-center items-center gap-x-6'>
              <p className='text-yellow-300 font-bold text-xl'>PIDELOS POR:</p>
              <a href="https://www.pedidosya.com.bo/" className='cursor-pointer'>
                <img src={PedidosYa}/>
              </a>
              <a href="https://farmacorp.com/search?type=article%2Cpage%2Cproduct&q=replex*" className='cursor-pointer'>
                <img src={Farmacorp}/>
              </a>
            </div>
            <div>
              <a href="tel:800105555" className='cursor-pointer'>
                <img src={CallCenter}/>
              </a>
            </div>
            <div className='flex flex-row justify-center items-center gap-x-4'>
              <a href="https://www.facebook.com/ReplexUltra" className='cursor-pointer'>
                <img src={Facebook}/>
              </a>
              <a href="https://www.instagram.com/replexinti/" className='cursor-pointer'>
                <img src={Instagram}/>
              </a>
              <a className='cursor-pointer'>
                <img src={Youtube}/>
              </a>
            </div>
          </div>
        </div>
        <div style={{backgroundColor: "#037369"}} className='w-screen flex flex-row justify-between items-center py-4 px-12'>
          <div className='leading-4'>
            <p className='text-white text-xs leading-4'>La vida no consiste simplemente en vivir, sino en estar bien.</p>
            <p className='text-white font-bold uppercase text-xs leading-4'>Si los síntomas persisten, consulte a su médico.</p>
            <p className='text-white text-xs leading-4'>Es un mensaje del Ministerio de Salud y Deportes.</p>
            <p className='text-white font-bold uppercase text-xs leading-4'>VENTA LIBRE SOLO EN FARMACIAS.</p>
          </div>
          <div>
            <p className='text-white text-xs leading-4'>
            Composición: <br/>
            Aerosol: Dietiltoluamida (DEET) 15%. Alcohol, fragancia,
            propilenglicol, propelente (propano - butano), miristato de <br/>
             isopropilo, irgasan DP 300. R.S. NSOC82801-22BO. Crema 200 mL:
             Diethyl toluamide (DEET) 7,5%, aqua, stearic acid, ceteareth-20, <br/>
              stearic alcohol, phenoxiethanol, ethylhexylglycerin, triethanolamine, 
              polysorbate 60, carbomer. R.S.NSOC82848-22BO. Crema 75 mL: <br/>
               Diethyl toluamide (DEET) 7,5%, aqua, stearic acid, ceteareth-20, 
               stearic alcohol, phenoxiethanol, ethylhexylglycerin, triethanolamine, <br/>
               polysorbate 60, carbomer. R.S. NSOC83318-22BO
            </p>
          </div>
          <img src={Logo}/>
        </div>
      </div>
      )}
      {mobile && (
          <div>
              <div className='flex flex-col justify-center items-center'>
                <div style={{backgroundColor: "#004630"}} className='w-screen pt-6 pb-4 px-2 flex flex-col justify-center gap-y-2'>
                  <div className='text-white font-bold flex flex-row justify-between items-center'>
                      <p className='text-yellow-300 font-bold text-sm'>PIDELOS POR:</p>
                      <div className='w-2/3 flex flex-row justify-end items-center'>
                        <a  href="https://www.pedidosya.com.bo/" className='cursor-pointer'>
                          <img className="w-5/6" src={PedidosYa}/>
                        </a>
                        <a href="https://farmacorp.com/search?type=article%2Cpage%2Cproduct&q=replex*" className='cursor-pointer'>
                          <img className="w-5/6" src={Farmacorp}/>
                        </a>
                      </div>
                  </div>
                  <div className='flex flex-row justify-between items-center gap-x-'>
                    <div>
                          <a href="tel:800105555" className='cursor-pointer'>
                            <img className="w-5/6" src={CallCenter}/>
                          </a>
                    </div>
                    <div className='flex flex-row justify-center items-center gap-x-2'>
                          <a  href="https://www.facebook.com/ReplexUltra" className='cursor-pointer'>
                            <img src={Facebook}/>
                          </a>
                          <a href="https://www.instagram.com/replexinti/" className='cursor-pointer'>
                            <img src={Instagram}/>
                          </a>
                          <a className='cursor-pointer'>
                            <img src={Youtube}/>
                          </a>
                        </div>
                    </div>
                  </div>
                  <div style={{backgroundColor: "#004630", lineHeight:"10px"}} className='w-screen flex flex-col justify-between items-center py-4 px-2 gap-y-4'>
                    <div className='leading-4 text-center'>
                      <p  className='text-white font-bold uppercase text-xs' style={{lineHeight:"12px"}}>La vida no consiste simplemente en vivir, sino en estar bien.</p>
                      <p className='text-white font-bold uppercase text-xs' style={{lineHeight:"12px"}}>Si los síntomas persisten, consulte a su médico.</p>
                      <p  className='text-white font-bold uppercase text-xs' style={{lineHeight:"12px"}}>Es un mensaje del Ministerio de Salud y Deportes.</p>
                      <p  className='text-white font-bold uppercase text-xs' style={{lineHeight:"12px"}}>VENTA LIBRE SOLO EN FARMACIAS.</p>
                    </div>
                    <div>
                      <p className='text-white text-xs' style={{lineHeight:"12px"}}>
                      Composición: <br/>
                      Aerosol: Dietiltoluamida (DEET) 15%. Alcohol, fragancia,
                      propilenglicol, propelente (propano - butano), miristato de 
                      isopropilo, irgasan DP 300. R.S. NSOC82801-22BO. Crema 200 mL: 
                      Diethyl toluamide (DEET) 7,5%, aqua, stearic acid, ceteareth-20, 
                        stearic alcohol, phenoxiethanol, ethylhexylglycerin, triethanolamine, 
                        polysorbate 60, carbomer. R.S.NSOC82848-22BO. Crema 75 mL:
                        Diethyl toluamide (DEET) 7,5%, aqua, stearic acid, ceteareth-20,
                        stearic alcohol, phenoxiethanol, ethylhexylglycerin, triethanolamine, 
                        polysorbate 60, carbomer. R.S. NSOC83318-22BO
                      </p>
                    </div>
                    <img className="w-2/4" src={Logo}/>
                  </div>
              </div>

          </div>
      )}
    </div>
  )
}

export default Footer