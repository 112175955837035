import React, { useState, useEffect} from 'react';
import Title from '../assets/title-quiz.png';
import animal1 from '../assets/croco.png';
import animal2 from '../assets/hippo.png';
import animal3 from '../assets/tiburon.png';
import animal4 from '../assets/wolf.png';
import animal5 from '../assets/snake.png';
import animal6 from '../assets/leon.png';
import animal7 from '../assets/elefante.png';
import animal8 from '../assets/mosquito.png';

function Quiz() {
  const images = [animal1, animal2, animal3, animal4, animal5, animal6, animal7, animal8];

  const initialTexts = {
    1: 'COCODRILO',
    2: 'HIPO',
    3: 'TIBURON',
    4: 'LOBO',
    5: 'SERPIENTE',
    6: 'LEÓN',
    7: 'ELEFANTE',
    8: 'MOSQUITO',
  };

  const [texts, setTexts] = useState(initialTexts);
  const [uniqueTexts, setUniqueTexts] = useState({
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
  });

  const [areImagesHidden, setAreImagesHidden] = useState(false);
  const [showDecesos, setShowDecesos] = useState(false);

  const handleDivClick = (animalId) => {
    // Toggle the state to hide/show images
    setAreImagesHidden(!areImagesHidden);
  
    // Toggle the state to show/hide "decesos"
    setShowDecesos(!areImagesHidden);
  
    // Update all unique texts
    setUniqueTexts({
      1: '1K',
      2: '500',
      3: '6',
      4: '10',
      5: '20K',
      6: '100',
      7: '500',
      8: '1M',
    });
  };
  

  //mobile view
  const [mobile, setMobile] = useState(null);
  const [windowWidth, setWindowWidth] = useState(null);
  const [inputValue, setInputValue] = useState(''); 
  const [isOpen, setIsOpen] = useState(false);


  const checkScreen = () => {
      setWindowWidth(window.innerWidth);
      setMobile(window.innerWidth <= 850);
      setIsOpen(false);
  };

  useEffect(() => {
      window.addEventListener('resize', checkScreen);
      checkScreen();

      return () => {
          window.removeEventListener('resize', checkScreen);
      };
  }, []);

  return (
    <div id="inicio" className="section">
      {!mobile && (
      <div className='flex flex-col justify-center items-center pt-20'>
        <img className="w-11/12" src={Title} alt="Title" />
        <div className='flex flex-col mt-10 gap-y-4'>
          <div className='flex flex-row gap-x-4 justify-center items-center'>
            {[1, 2, 3, 4].map((animalId) => (
              <div
                key={animalId}
                className={`cursor-pointer group w-40 h-40 flex flex-col justify-between items-center border-8 border-yellow-300 rounded-2xl transform hover:scale-110 transition-transform duration-500 ease-in-out`}
                style={{ backgroundColor: "#037369" }}
                onClick={() => handleDivClick(animalId)}
              >
                <div className='flex flex-col justify-center items-center h-full'>
                  {areImagesHidden ? (
                    <p className='text-white font-bold text-center text-5xl'>{uniqueTexts[animalId]}</p>
                  ) : (
                    <img style={{ height: "6vh" }} src={images[animalId - 1]} alt={`Animal ${animalId}`} />
                  )}
                </div>
                <div className='w-full text-center py-2' style={{ backgroundColor: "#06C5B4" }}>
                  {showDecesos && <p className='text-white font-bold text-center text-2xl'>decesos</p>}
                  {!areImagesHidden && <p className='text-white font-bold text-xl'>{texts[animalId]}</p>}
                </div>
              </div>
            ))}
          </div>
          <div className='flex flex-row gap-x-4'>
            {[5, 6, 7, 8].map((animalId) => (
              <div
                key={animalId}
                className={`cursor-pointer group w-40 h-40 flex flex-col justify-between items-center border-8 border-yellow-300 rounded-2xl transform hover:scale-110 transition-transform duration-500 ease-in-out`}
                style={{ backgroundColor: "#037369" }}
                onClick={() => handleDivClick(animalId)}
              >
                <div className='flex flex-col justify-center items-center h-full'>
                  {areImagesHidden ? (
                    <p className="font-bold text-center text-5xl" style={{ color: uniqueTexts[animalId] === '1M' ? '#FF0000' : '#FFFFFF' }}>
                    {uniqueTexts[animalId]}
                    </p>
                  ) : (
                    <img style={{ height: "6vh" }} src={images[animalId - 1]} alt={`Animal ${animalId}`} />
                  )}
                </div>
                <div className='w-full text-center py-2' style={{ backgroundColor: "#06C5B4" }}>
                  {showDecesos && <p className='text-white font-bold text-2xl'>decesos</p>}
                  {!areImagesHidden && <p className='text-white font-bold text-xl'>{texts[animalId]}</p>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      )}
      {mobile && (
          <div>
              <div className='flex flex-col justify-center items-center pt-24 px-2'>
                <img src={Title} alt="Title" />
                <div className='flex flex-col mt-4 gap-y-2'>
                  <div className='flex flex-row gap-x-2 justify-center items-center'>
                    {[1, 2, 3].map((animalId) => (
                      <div
                        key={animalId}
                        className={`cursor-pointer group w-28 h-38 flex flex-col justify-between items-center border-8 border-yellow-300 rounded-2xl pt-4 gap-y-4 transform hover:scale-110 transition-transform duration-500 ease-in-out`}
                        style={{ backgroundColor: "#037369" }}
                        onClick={() => handleDivClick(animalId)}
                      >
                        <div className='flex flex-col justify-center items-center h-full'>
                          {areImagesHidden ? (
                            <p className='text-white font-bold text-center text-4xl'>{uniqueTexts[animalId]}</p>
                          ) : (
                            <img style={{ height: "4vh" }} src={images[animalId - 1]} alt={`Animal ${animalId}`} />
                          )}
                        </div>
                        <div className='w-full text-center py-2' style={{ backgroundColor: "#06C5B4" }}>
                          {showDecesos && <p className='text-white font-bold text-center text-base'>decesos</p>}
                          {!areImagesHidden && <p className='text-white font-bold text-sm'>{texts[animalId]}</p>}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='flex flex-row gap-x-2 justify-center items-center'>
                    {[4, 5, 6].map((animalId) => (
                      <div
                        key={animalId}
                        className={`cursor-pointer group w-28 h-38 flex flex-col justify-between items-center border-8 border-yellow-300 rounded-2xl pt-4 gap-y-4 transform hover:scale-110 transition-transform duration-500 ease-in-out`}
                        style={{ backgroundColor: "#037369" }}
                        onClick={() => handleDivClick(animalId)}
                      >
                        <div className='flex flex-col justify-center items-center h-full'>
                          {areImagesHidden ? (
                            <p className='text-white font-bold text-center text-2xl'>{uniqueTexts[animalId]}</p>
                          ) : (
                            <img style={{ height: "4vh" }} src={images[animalId - 1]} alt={`Animal ${animalId}`} />
                          )}
                        </div>
                        <div className='w-full text-center py-2' style={{ backgroundColor: "#06C5B4" }}>
                          {showDecesos && <p className='text-white font-bold text-center text-sm'>decesos</p>}
                          {!areImagesHidden && <p className='text-white font-bold text-sm'>{texts[animalId]}</p>}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='flex flex-row gap-x-2 justify-center items-center'>
                    {[7, 8].map((animalId) => (
                      <div
                        key={animalId}
                        className={`cursor-pointer group w-28 h-38 flex flex-col justify-between items-center border-8 border-yellow-300 rounded-2xl pt-4 gap-y-4 transform hover:scale-110 transition-transform duration-500 ease-in-out`}
                        style={{ backgroundColor: "#037369" }}
                        onClick={() => handleDivClick(animalId)}
                      >
                        <div className='flex flex-col justify-center items-center h-full'>
                          {areImagesHidden ? (
                            <p className="font-bold text-center text-2xl" style={{ color: uniqueTexts[animalId] === '1M' ? '#FF0000' : '#FFFFFF' }}>
                            {uniqueTexts[animalId]}
                            </p>
                          ) : (
                            <img style={{ height: "4vh" }} src={images[animalId - 1]} alt={`Animal ${animalId}`} />
                          )}
                        </div>
                        <div className='w-full text-center py-2' style={{ backgroundColor: "#06C5B4" }}>
                          {showDecesos && <p className='text-white font-bold text-center text-sm'>decesos</p>}
                          {!areImagesHidden && <p className='text-white font-bold text-sm'>{texts[animalId]}</p>}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
          </div>
      )}
    </div>
  );
}

export default Quiz;
