import React, { useState, useEffect} from 'react';
import Title from '../assets/enfermedades.png'
import mosquito1 from '../assets/mosquito1.png'
import mosquito2 from '../assets/mosquito2.png'
import mosquito3 from '../assets/mosquito3.png'
import mosquito4 from '../assets/mosquito4.png'
import mosquito5 from '../assets/drunk.png'
import Fiebre from '../assets/Asset 4.png'
import Dengue from '../assets/Asset 5.png'
import Malaria from '../assets/Asset 6.png'
import Zika from '../assets/Asset 7.png'
import Chiku from '../assets/Asset 8.png'
import Arrow from '../assets/arrow-right.png'
import contagio1 from "../assets/contagio1.png"
import contagio2 from "../assets/contagio2.png"
import contagio3 from "../assets/contagio3.png"
import contagio4 from "../assets/contagio4.png"
import contagio5 from "../assets/contagio5.png"

function Enfermedades() {
     //mobile view
    const [mobile, setMobile] = useState(null);
    const [windowWidth, setWindowWidth] = useState(null);
    const [inputValue, setInputValue] = useState(''); 


    const checkScreen = () => {
        setWindowWidth(window.innerWidth);
        setMobile(window.innerWidth <= 850);
    };

    useEffect(() => {
        window.addEventListener('resize', checkScreen);
        checkScreen();

        return () => {
            window.removeEventListener('resize', checkScreen);
        };
    }, []);

  return (
    <div id="enfermedades" className="section">
        {!mobile && (
        <div className='flex flex-col justify-center items-center gap-y-24'>
            <div> 
                <img src={Title}/>
            </div>
            {/* ENFERMEDAD 1 */}
            <div className='flex flex-col justify-center items-center text-center gap-y-10'>
                <div className='text-center'>
                    <h1 className='title-sick'>FIEBRE AMARILLA</h1>
                    <p className='cantidad'>400.000</p>
                    <p className='contagios'>CONTAGIOS ANUALES</p>
                </div>
                <div className='flex flex-col justify-start text-start items-start gap-y-6'>
                    <p className='enfermedades'>
                        La fiebre amarilla es una enfermedad viral transmitida por mosquitos, principalmente Aedes aegypti en áreas urbanas y Haemagogus en áreas selváticas. Causa fiebre, ictericia (coloración amarilla de la piel y ojos), hemorragias y puede llevar a insuficiencia orgánica grave y muerte. 
                    </p>
                    <p className='enfermedades'>
                        La cantidad de contagios y muertes por fiebre amarilla varía de un año a otro y de una región a otra. La fiebre amarilla es endémica en algunas áreas de África y América del Sur, donde la cantidad de casos y muertes puede fluctuar debido a diversos factores, como el clima, la cobertura de vacunación y la presencia de mosquitos vectores. En áreas con brotes de fiebre amarilla, los números pueden ser significativamente más altos durante esos períodos.
                    </p>
                </div>
                <div className='sick-box rounded-2xl flex flex-col py-6 px-8'>
                    <h1 className='sintomas'>SÍNTOMAS:</h1>
                    <p className='enfermedades-white text-white'>
                        Fiebre alta, dolores de cabeza, dolores musculares y articulares, debilidad y fatiga, náuseas y vómitos, dolor en la parte baja de la espalda, la piel y los ojos pueden volverse amarillos, sangrados de encías, nariz, estómago o intestinos, disfunción del hígado y los riñones.
                    </p>
                </div>
            </div>
            {/* ENFERMEDAD 2 */}
            <div className='flex flex-col justify-center items-center text-center gap-y-10'>
                <div className='text-center'>
                    <h1 className='title-sick'>DENGUE</h1>
                    <p className='cantidad'>96.000.000</p>
                    <p className='contagios'>CONTAGIOS ANUALES</p>
                </div>
                <div className='flex flex-col justify-start text-start items-start gap-y-6'>
                    <p className='enfermedades'>
                         El dengue es una enfermedad endémica viral transmitida principalmente por el mosquito Aedes aegypti en muchas regiones tropicales y subtropicales del mundo. En algunos casos, la enfermedad puede evolucionar hacia una forma más grave, conocida como "dengue grave" o "fiebre hemorrágica del dengue". Esta forma severa puede causar hemorragias internas, disminución de la presión arterial y daño en órganos, y en casos extremos, puede ser mortal.
                    </p>
                     <p className='enfermedades'>
                        La prevención del dengue se centra en el control de los mosquitos que se crían en agua estancada y en la protección personal contra las picaduras de mosquitos. No existe un tratamiento específico para el dengue, por lo que el manejo se concentra en aliviar los síntomas y brindar cuidados médicos en casos graves.
                    </p>
                </div>
                <div className='sick-box rounded-2xl flex flex-col py-6 px-8'>
                    <h1 className='sintomas'>SÍNTOMAS</h1>
                    <p className='enfermedades-white text-white'>
                        Fiebre alta, dolor de cabeza, dolor detrás de los ojos, dolores musculares y articulares, erupción cutánea, debilidad y fatiga, sangrados de encías, nariz, tracto gastrointestinal o en otros lugares, insuficiencia hepática, renal o cardíaca.
                    </p>
                </div>
            </div>
            {/* ENFERMEDAD 3 */}
            <div className='flex flex-col justify-center items-center text-center gap-y-10'>
                <div className='text-center'>
                    <h1 className='title-sick'>MALARIA</h1>
                    <p className='cantidad'>241.000.000</p>
                    <p className='contagios'>CONTAGIOS ANUALES</p>
                </div>
                <div className='flex flex-col justify-start text-start items-start gap-y-6'>
                    <p className='enfermedades'>
                        La malaria, también conocida como paludismo, es una enfermedad parasitaria transmitida por mosquitos, causada por diferentes especies del parásito del género Plasmodium. La malaria es una de las enfermedades más mortales y prevalentes en áreas tropicales y subtropicales del mundo.
                    </p>
                    <p className='enfermedades'>
                        La prevención y control de la malaria son esenciales. Esto incluye medidas como el uso de mosquiteros tratados con insecticida, la eliminación de criaderos de mosquitos, la quimioprofilaxis para viajeros y, en algunas áreas, la vacunación. El tratamiento eficaz de la malaria implica medicamentos antipalúdicos específicos.
                         A pesar de los avances en la prevención y tratamiento, la malaria sigue siendo una amenaza significativa para la salud pública en muchas partes del mundo. 
                     </p>
                </div>
                <div className='sick-box rounded-2xl flex flex-col py-6 px-8'>
                    <h1 className='sintomas'>SÍNTOMAS</h1>
                    <p className='enfermedades-white text-white'>
                        Fiebre cíclica, escalofríos y temblores, sudoración profusa, dolor de cabeza, dolores musculares y articulares, debilidad, cansancio, náuseas, vómitos y diarrea.
                        <br/> En casos graves, puede causar anemia, insuficiencia renal, disfunción hepática y daño cerebral. 
                    </p>
                </div>
            </div>
            {/* ENFERMEDAD 4 */}
            <div className='flex flex-col justify-center items-center text-center gap-y-10'>
                <div className='text-center'>
                    <h1 className='title-sick'>ZIKA</h1>
                    <p className='cantidad'>900.000</p>
                    <p className='contagios'>CONTAGIOS ANUALES</p>
                </div>
                <div className='flex flex-col justify-start text-start items-start gap-y-6'>
                    <p className='enfermedades'>
                        El virus del Zika es una enfermedad viral transmitida por los mosquitos, pero también puede transmitirse por vía sexual y de madre a hijo durante el embarazo o el parto. La infección suele ser leve o asintomática en la mayoría de las personas pero la principal preocupación radica en su capacidad para afectar a los fetos en desarrollo cuando las mujeres embarazadas se infectan. La infección durante el embarazo puede causar microcefalia, una afección en la que el bebé nace con un cráneo más pequeño y problemas neurológicos graves. 
                    </p>
                    <p className='enfermedades'>
                         La prevención del Zika se centra en evitar las picaduras de mosquitos usando repelentes y ropa protectora, practicar el sexo seguro si se ha viajado a áreas de riesgo y, en particular, en el control de los mosquitos Aedes aegypti. No existe una vacuna específica para el Zika, pero se han realizado investigaciones para desarrollar una.
                    </p>
                </div>
                <div className='sick-box rounded-2xl flex flex-col py-6 px-8'>
                    <h1 className='sintomas'>SÍNTOMAS</h1>
                    <p className='enfermedades-white text-white'>
                        Fiebre leve, sarpullido, dolor en las articulaciones, conjuntivitis y malestar general. En raras ocasiones puede provocar síndrome de Guillain-Barré, que afecta el sistema nervioso.
                    </p>
                </div>
            </div>
            {/* ENFERMEDAD 5 */}
            <div className='flex flex-col justify-center items-center text-center gap-y-10'>
                <div className='text-center'>
                    <h1 className='title-sick'>CHIKUNGUYA</h1>
                    <p className='cantidad'>330.000</p>
                    <p className='contagios'>CONTAGIOS ANUALES</p>
                </div>
                <div className='flex flex-col justify-start text-start items-start gap-y-6'>
                    <p className='enfermedades'>
                        La fiebre chikungunya es una enfermedad viral transmitida principalmente por los mosquitos Aedes aegypti y Aedes albopictus. La palabra "chikungunya" proviene del idioma makonde y significa "doblarse", reflejando la postura encorvada característica de las personas que padecen los intensos dolores en las articulaciones que acompañan a esta enfermedad.
                    </p>
                    <p className='enfermedades'>
                         Los síntomas suelen aparecer de 2 a 12 días después de la picadura del mosquito infectado. Aunque la mayoría de los pacientes se recupera por completo en unas semanas, algunos pueden experimentar dolor en las articulaciones durante meses o incluso años, lo que se conoce como "artritis crónica post-chikungunya".
                        A pesar de que la chikungunya rara vez es mortal, sus síntomas pueden ser altamente debilitantes y afectar significativamente la calidad de vida de los pacientes. 
                    </p>
                </div>
                <div className='sick-box rounded-2xl flex flex-col py-6 px-8'>
                    <h1 className='sintomas'>SÍNTOMAS</h1>
                    <p className='enfermedades-white text-white'>
                        Fiebre repentina y alta, dolor intenso en las articulaciones, dolores de cabeza intensos, erupción cutánea en la piel, dolores musculares, debilidad y fatiga, náuseas y vómitos.
                    </p>
                </div>
            </div>
        </div>
        )}
        {mobile && (
            <div className='flex flex-col justify-center items-center gap-y-10 px-2'>
                <div> 
                    <img src={Title}/>
                </div>
                <div className='flex flex-col justify-center items-start'>
                    <div className='flex flex-col justify-center items-center h-full'>
                        <div className='flex flex-col gap-y-8'>
                            <div className='flex flex-row justify-center items-center gap-x-4'>
                                <img src={Arrow}/>
                                <img src={Fiebre} style={{height:"10vh"}}/>
                            </div>
                            <p className='enfermedades text-center'>
                            La fiebre amarilla es una enfermedad viral transmitida por mosquitos, principalmente Aedes aegypti en áreas urbanas y Haemagogus en áreas selváticas. Causa fiebre, ictericia (coloración amarilla de la piel y ojos), hemorragias y puede llevar a insuficiencia orgánica grave y muerte. 
                            </p>
                            <p className='enfermedades text-center'>
                                La cantidad de contagios y muertes por fiebre amarilla varía de un año a otro y de una región a otra. La fiebre amarilla es endémica en algunas áreas de África y América del Sur, donde la cantidad de casos y muertes puede fluctuar debido a diversos factores, como el clima, la cobertura de vacunación y la presencia de mosquitos vectores. En áreas con brotes de fiebre amarilla, los números pueden ser significativamente más altos durante esos períodos.
                            </p>
                            <div className='sick-box rounded-2xl flex flex-col py-6 px-8'>
                                <h1 className='sintomas text-center'>SÍNTOMAS:</h1>
                                <p className='enfermedades-white text-white text-center'>
                                    fiebre, escalofríos, pérdidad de apetito, náuseas, dolor muscular, dolor de cabeza. 
                                    Variedad hemorrágica más grave.
                                </p>
                            </div>
                        </div>
                        <div className='w-2/3 flex flex-col justify-between items-between gap-y-8 pt-8'>
                            <img src={contagio1}/>
                            <img src={mosquito1}/>
                        </div>
                    </div>
                    <div className='flex flex-col justify-center items-center h-full'>
                        <div className='flex flex-col gap-y-8'>
                            <div className='flex flex-row justify-center items-center gap-x-4'>
                                <img src={Arrow}/>
                                <img src={Dengue} style={{height:"5.5vh"}}/>
                            </div>
                            <p className='enfermedades text-center'>
                                El dengue es una enfermedad endémica viral transmitida principalmente por el mosquito Aedes aegypti en muchas regiones tropicales y subtropicales del mundo. En algunos casos, la enfermedad puede evolucionar hacia una forma más grave, conocida como "dengue grave" o "fiebre hemorrágica del dengue". Esta forma severa puede causar hemorragias internas, disminución de la presión arterial y daño en órganos, y en casos extremos, puede ser mortal.
                            </p>
                            <p className='enfermedades text-center'>
                                La prevención del dengue se centra en el control de los mosquitos que se crían en agua estancada y en la protección personal contra las picaduras de mosquitos. No existe un tratamiento específico para el dengue, por lo que el manejo se concentra en aliviar los síntomas y brindar cuidados médicos en casos graves.
                            </p>
                            <div className='sick-box rounded-2xl flex flex-col py-6 px-8'>
                                <h1 className='sintomas text-center'>SÍNTOMAS</h1>
                                <p className='enfermedades-white text-white text-center'>
                                    Fiebre alta, dolores de cabeza, dolores musculares y articulares, debilidad y fatiga, náuseas y vómitos, dolor en la parte baja de la espalda, la piel y los ojos pueden volverse amarillos, sangrados de encías, nariz, estómago o intestinos, disfunción del hígado y los riñones.
                                </p>
                            </div>
                        </div>
                        <div className='w-2/3 flex flex-col justify-between items-between gap-y-8 pt-8'>
                            <img src={contagio2}/>
                            <img src={mosquito2}/>
                        </div>
                    </div>
                    <div className='flex flex-col justify-center items-center h-full'>
                        <div className='flex flex-col gap-y-8'>
                            <div className='flex flex-row justify-center items-center gap-x-4'>
                                <img src={Arrow}/>
                                <img src={Malaria} style={{height:"5.5vh"}}/>
                            </div>
                            <p className='enfermedades text-center'>
                                La malaria, también conocida como paludismo, es una enfermedad parasitaria transmitida por mosquitos, causada por diferentes especies del parásito del género Plasmodium. La malaria es una de las enfermedades más mortales y prevalentes en áreas tropicales y subtropicales del mundo.
                            </p>
                            <p className='enfermedades text-center'>
                                La prevención y control de la malaria son esenciales. Esto incluye medidas como el uso de mosquiteros tratados con insecticida, la eliminación de criaderos de mosquitos, la quimioprofilaxis para viajeros y, en algunas áreas, la vacunación. El tratamiento eficaz de la malaria implica medicamentos antipalúdicos específicos.
                                A pesar de los avances en la prevención y tratamiento, la malaria sigue siendo una amenaza significativa para la salud pública en muchas partes del mundo. 
                            </p>
                            <div className='sick-box rounded-2xl flex flex-col py-6 px-8'>
                                <h1 className='sintomas text-center'>SÍNTOMAS</h1>
                                <p className='enfermedades-white text-white text-center'>
                                    Fiebre alta, dolor de cabeza, dolor detrás de los ojos, dolores musculares y articulares, erupción cutánea, debilidad y fatiga, sangrados de encías, nariz, tracto gastrointestinal o en otros lugares, insuficiencia hepática, renal o cardíaca.
                                </p>
                            </div>
                        </div>
                        <div className='w-2/3 flex flex-col justify-between items-between gap-y-8 pt-8'>
                            <img src={contagio3}/>
                            <img src={mosquito3}/>
                        </div>
                    </div>
                    <div className='flex flex-col justify-center items-center h-full'>
                        <div className='flex flex-col gap-y-8'>
                            <div className='flex flex-row justify-center items-center gap-x-4'>
                                <img src={Arrow}/>
                                <img src={Zika} style={{height:"5.5vh"}}/>
                            </div>
                            <p className='enfermedades text-center'>
                                El virus del Zika es una enfermedad viral transmitida por los mosquitos, pero también puede transmitirse por vía sexual y de madre a hijo durante el embarazo o el parto. La infección suele ser leve o asintomática en la mayoría de las personas pero la principal preocupación radica en su capacidad para afectar a los fetos en desarrollo cuando las mujeres embarazadas se infectan. La infección durante el embarazo puede causar microcefalia, una afección en la que el bebé nace con un cráneo más pequeño y problemas neurológicos graves. 
                            </p>
                            <p className='enfermedades text-center'>
                                La prevención del Zika se centra en evitar las picaduras de mosquitos usando repelentes y ropa protectora, practicar el sexo seguro si se ha viajado a áreas de riesgo y, en particular, en el control de los mosquitos Aedes aegypti. No existe una vacuna específica para el Zika, pero se han realizado investigaciones para desarrollar una.
                            </p>
                           <div className='sick-box rounded-2xl flex flex-col py-6 px-8'>
                                <h1 className='sintomas text-center'>SÍNTOMAS</h1>
                                <p className='enfermedades-white text-white text-center'>
                                    Fiebre cíclica, escalofríos y temblores, sudoración profusa, dolor de cabeza, dolores musculares y articulares, debilidad, cansancio, náuseas, vómitos y diarrea.
                                    <br/> En casos graves, puede causar anemia, insuficiencia renal, disfunción hepática y daño cerebral. 
                                </p>
                            </div>
                        </div>
                        <div className='w-2/3 flex flex-col justify-between items-between gap-y-8 pt-8'>
                            <img src={contagio4}/>
                            <img src={mosquito4}/>
                        </div>
                    </div>
                    <div className='flex flex-col justify-center items-center h-full'>
                        <div className='flex flex-col gap-y-8'>
                            <div className='flex flex-row justify-center items-center gap-x-4'>
                                <img src={Arrow}/>
                                <img src={Chiku} style={{height:"4.6vh"}}/>
                            </div>
                            <p className='enfermedades text-center'>
                                La fiebre chikungunya es una enfermedad viral transmitida principalmente por los mosquitos Aedes aegypti y Aedes albopictus. La palabra "chikungunya" proviene del idioma makonde y significa "doblarse", reflejando la postura encorvada característica de las personas que padecen los intensos dolores en las articulaciones que acompañan a esta enfermedad.
                            </p>
                            <p className='enfermedades text-center'>
                                Los síntomas suelen aparecer de 2 a 12 días después de la picadura del mosquito infectado. Aunque la mayoría de los pacientes se recupera por completo en unas semanas, algunos pueden experimentar dolor en las articulaciones durante meses o incluso años, lo que se conoce como "artritis crónica post-chikungunya".
                                A pesar de que la chikungunya rara vez es mortal, sus síntomas pueden ser altamente debilitantes y afectar significativamente la calidad de vida de los pacientes. 
                            </p>
                            <div className='sick-box rounded-2xl flex flex-col py-6 px-8'>
                            <h1 className='sintomas text-center'>SÍNTOMAS</h1>
                            <p className='enfermedades-white uppercase text-white'>
                                Fiebre leve, sarpullido, dolor en las articulaciones, conjuntivitis y malestar general. En raras ocasiones puede provocar síndrome de Guillain-Barré, que afecta el sistema nervioso.
                            </p>
                        </div>
                        </div>
                        <div className='w-2/3 flex flex-col justify-between items-between gap-y-8 pt-8'>
                            <img src={contagio5}/>
                            <img src={mosquito5}/>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </div>
  )
}

export default Enfermedades