import React, { useRef, lazy, Suspense, useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useInView } from 'react-intersection-observer';
import Warning from '../assets/warning.png'
import Arrow from '../assets/arrow.png'
import ArrowRight from '../assets/arrow-right.png'
import Huevos from '../assets/Asset 10.png';
import MosquitoBg from '../assets/mosquito.MOV'

const ThreeScene = lazy(() => import('./ThreeScene'));

function Mosquito3d() {
    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger once
      });

     //mobile view
  const [mobile, setMobile] = useState(null);
  const [windowWidth, setWindowWidth] = useState(null);
  const [inputValue, setInputValue] = useState(''); 
  const [isOpen, setIsOpen] = useState(false);


  const checkScreen = () => {
      setWindowWidth(window.innerWidth);
      setMobile(window.innerWidth <= 850);
      setIsOpen(false);
  };

  useEffect(() => {
      window.addEventListener('resize', checkScreen);
      checkScreen();

      return () => {
          window.removeEventListener('resize', checkScreen);
      };
  }, []);

  return (
    <div id="mosquito" className="section">
        {!mobile && (
        <div className='mosquito-container flex flex-col border-8 border-lime-950 px-10 pt-10 pb-16' style={{width:"67vw"}}>
            <video
            autoPlay
            loop
            muted
            playsInline
            className="video-background bg-black"
          >
            <source src={MosquitoBg} type="video/mp4" />
          </video>
            <div className='text-center flex flex-row justify-center items-center gap-x-2'>
                <img src={Warning}/>
                <h1 className='leading-6'>EL ANIMAL MÁS PELIGROSO <br/> PARA EL SER HUMANO</h1>
                <img src={Warning}/>
            </div>
            <div className='flex flex-row justify-between pt-10 pb-10'>
                <div className='flex flex-col justify-center items-end gap-y-16'>
                    <div className='flex flex-row justify-between items-center gap-x-4'>
                        <div className='flex flex-col text-end border-b-2 pb-2 border-yellow-400'>
                            <p className='text-white font-bold text-lg leading-6 uppercase'>750.000</p>
                            <p className='text-white font-bold text-lg leading-6 uppercase'>MUERTES ANUALES</p>
                        </div>
                        <img src={Arrow}/>
                    </div>
                    <div className='flex flex-row justify-between items-center gap-x-4'>
                        <div className='flex flex-col text-end border-b-2 pb-2 border-yellow-400'>
                            <p className='text-white font-bold text-lg leading-6 uppercase'>VELOCIDAD DE</p>
                            <p className='text-white font-bold text-lg leading-6 uppercase'> HASTA 3.22 Km/h</p>
                        </div>
                        <img src={Arrow}/>
                    </div>
                    <div className='flex flex-row justify-between items-center gap-x-4'>
                        <div className='flex flex-col text-end border-b-2 pb-2 border-yellow-400'>
                            <p className='text-white font-bold text-lg leading-6 uppercase'>Activos 24 horas</p>
                        </div>
                        <img src={Arrow}/>
                    </div>
                </div>
                <div>
                    <Suspense fallback={<div>Loading...</div>}>
                         {/* <ThreeScene /> */}
                    </Suspense>
                </div>
                <div>
                    <div className='flex flex-col justify-center items-start gap-y-16'>
                        <div className='flex flex-row justify-between items-center gap-x-4'>
                            <img src={ArrowRight}/>
                            <div className='flex flex-col border-b-2 pb-2 border-yellow-400'>
                                <p className='text-white font-bold text-lg leading-6 uppercase'>TRANSMITE MÁS</p>
                                <p className='text-white font-bold text-lg leading-6 uppercase'>DE 10 ENFERMEDADES</p>
                                <p className='text-white font-bold text-lg leading-6 uppercase'>LETALES</p>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between items-center gap-x-4'>
                            <img src={ArrowRight}/>
                            <div className='flex flex-col border-b-2 pb-2 border-yellow-400'>
                                <p className='text-white font-bold text-lg leading-6 uppercase'>Mide de 5 a</p>
                                <p className='text-white font-bold text-lg leading-6 uppercase'>10 milimetros</p>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between items-center gap-x-4'>
                            <img src={ArrowRight}/>
                            <div className='flex flex-col border-b-2 pb-2 border-yellow-400'>
                                <p className='text-white font-bold text-lg leading-6 uppercase'>LAS HEMBRAS SON</p>
                                <p className='text-white font-bold text-lg leading-6 uppercase'>TRANSMISORAS</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-row justify-center items-center'>
                <img className='w-2/6' src={Huevos}/>
            </div>
        </div>
        )}
        {mobile && (
            <div>
                <div className='mosquito-container flex flex-col border-4 border-lime-950 px-10 pt-4 pb-16' style={{width:"90vw", height:"40vh"}}>
                    <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="video-background bg-black pt-12"
                    >
                        <source src={MosquitoBg} type="video/mp4" />
                    </video>
                    <div className='text-center flex flex-row justify-center items-center'>
                        <img src={Warning}/>
                        <h1 className='text-base' style={{lineHeight:"14px"}}>EL ANIMAL MÁS PELIGROSO <br/> PARA EL SER HUMANO</h1>
                        <img src={Warning}/>
                    </div>
                </div>
            </div>
        )}
    </div>
  )
}

export default Mosquito3d