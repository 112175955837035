import React, { useState, useEffect } from 'react';
import Logo from '../assets/logo.png'

function Navbar() {
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(true);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isVisible = prevScrollPos > currentScrollPos;
  
      setPrevScrollPos(currentScrollPos);
      setVisible(isVisible);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [prevScrollPos, visible]);

    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
    
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: 'smooth', 
        });
      }
    };

      //mobile view
    const [mobile, setMobile] = useState(null);
    const [windowWidth, setWindowWidth] = useState(null);
    const [inputValue, setInputValue] = useState(''); 
    const [isOpen, setIsOpen] = useState(false);


    const checkScreen = () => {
        setWindowWidth(window.innerWidth);
        setMobile(window.innerWidth <= 850);
        setIsOpen(false);
    };

    useEffect(() => {
        window.addEventListener('resize', checkScreen);
        checkScreen();

        return () => {
            window.removeEventListener('resize', checkScreen);
        };
    }, []);

  return (
    <div>
      {!mobile && (
      <div
        style={{
          backgroundColor: "#02635A",
          backgroundOpacity: "60%",
          transition: "top 0.3s",
          top: visible ? "0" : "-100px",
          zIndex:"500"
        }}
        className='fixed w-screen'
      >
          <div
          style={{
            paddingTop:"10px",
            paddingBottom:"10px",
          }}
          className='flex flex-row justify-between items-center px-12'>
          <svg xmlns="http://www.w3.org/2000/svg" width="192" height="23" viewBox="0 0 192 36" fill="none">
              <g clip-path="url(#clip0_1_3)">
                  <path d="M0 0.76C2.66 0.34 6.66 0 11.37 0C16.77 0 20.63 0.74 23.28 2.74C25.54 4.46 26.76 6.97 26.76 10.41C26.76 14.79 23.47 17.91 20.72 18.91V19.09C23.06 20.09 24.33 22.33 25.23 25.39C26.29 28.96 27.45 34.08 28.13 35.43H18.5C17.96 34.41 17.05 31.66 16.03 27.33C15.07 23.12 13.78 22.24 10.87 22.24H9.34V35.43H0V0.76ZM9.34 15.5H11.79C15.34 15.5 17.39 13.77 17.39 11.13C17.39 8.49 15.65 7 12.52 6.97C10.75 6.97 9.81 7.06 9.34 7.18V15.5Z" fill="white"/>
                  <path d="M54.29 21.26H41.64V27.74H55.78V35.43H32.21V0.27H55.05V7.97H41.64V13.65H54.29V21.27V21.26Z" fill="white"/>
                  <path d="M61.02 0.76C63.58 0.35 67.27 0 72.63 0C77.7 0 81.32 0.91 83.79 2.8C86.2 4.58 87.8 7.68 87.8 11.27C87.8 14.86 86.61 17.95 84.38 19.94C81.53 22.49 77.41 23.62 72.65 23.62C71.74 23.62 70.93 23.55 70.35 23.51V35.44H61.01V0.76H61.02ZM70.36 16.28C70.87 16.37 71.49 16.41 72.51 16.41C76.18 16.41 78.48 14.52 78.48 11.5C78.48 8.88 76.74 7.1 73.15 7.1C71.77 7.1 70.83 7.21 70.35 7.32V16.28H70.36Z" fill="white"/>
                  <path d="M92.45 0.27H101.88V27.54H115.43V35.43H92.45V0.26V0.27Z" fill="white"/>
                  <path d="M142.09 21.26H129.44V27.74H143.58V35.43H120.01V0.26H142.85V7.96H129.44V13.64H142.09V21.26Z" fill="white"/>
                  <path d="M166.99 35.44L164.39 29.69C163.36 27.45 162.58 25.66 161.67 23.62H161.56C160.94 25.66 160.14 27.48 159.27 29.65L156.93 35.44H146.21L156.12 17.63L146.56 0.27H157.4L159.79 5.87C160.7 7.96 161.37 9.68 162.14 11.67H162.28C163.07 9.38 163.6 7.83 164.33 5.89L166.5 0.27H177.25L167.68 17.44L177.89 35.44H166.98H166.99Z" fill="white"/>
                  <path d="M185.16 2.31C185.51 2.31 186.02 2.34 186.49 2.43C187.23 2.56 187.71 2.78 188.04 3.15C188.31 3.43 188.46 3.82 188.46 4.24C188.46 4.94 188.01 5.33 187.45 5.43V5.45C187.86 5.66 188.11 6.1 188.24 6.67C188.42 7.45 188.54 7.99 188.65 8.21L187.93 8.08C187.84 7.91 187.73 7.43 187.58 6.73C187.42 5.97 187.13 5.63 186.5 5.5L185.84 5.38V7.7L185.15 7.58V2.31H185.16ZM185.85 4.87L186.56 4.99C187.3 5.12 187.77 4.8 187.77 4.19C187.77 3.49 187.27 3.1 186.54 2.97C186.21 2.91 185.97 2.9 185.86 2.91V4.87H185.85Z" fill="white"/>
                  <path d="M186.91 9.95C184.29 9.49 182.16 6.98 182.16 4.36C182.16 1.74 184.29 -0.0200001 186.91 0.45C189.53 0.91 191.66 3.42 191.66 6.04C191.66 8.66 189.53 10.42 186.91 9.96V9.95ZM186.91 1.13C184.67 0.74 182.85 2.24 182.85 4.48C182.85 6.72 184.67 8.86 186.91 9.26C189.15 9.66 190.98 8.15 190.98 5.92C190.98 3.69 189.15 1.54 186.91 1.14V1.13Z" fill="white"/>
              </g>
              <defs>
                  <clipPath id="clip0_1_3">
                  <rect width="191.66" height="35.44" fill="white"/>
                  </clipPath>
              </defs>
              </svg>
              <div className='flex flex-row justify-center items-center gap-x-8 font-bold text-white'>
                  <a onClick={() => scrollToSection('inicio')} className='cursor-pointer hover:text-yellow-300'>
                      INICIO
                  </a>
                  <a onClick={() => scrollToSection('mosquito')} className='cursor-pointer hover:text-yellow-300'>
                      MOSQUITO
                  </a>
                  <a onClick={() => scrollToSection('enfermedades')} className='cursor-pointer hover:text-yellow-300'>
                      ENFERMEDADES
                  </a>
                  <a onClick={() => scrollToSection('replex')} className='cursor-pointer hover:text-yellow-300'>
                      REPLEX
                  </a>
                  <a onClick={() => scrollToSection('deet')} className='cursor-pointer hover:text-yellow-300'>
                      DEET
                  </a>
                  <a onClick={() => scrollToSection('pronostico')} className='cursor-pointer hover:text-yellow-300'>
                      PRONÓSTICO
                  </a>
              </div>
          </div>
      </div>
      )}
      {mobile && (
          <div>
              <div
                style={{
                  backgroundColor: "#02635A",
                  backgroundOpacity: "60%",
                  transition: "top 0.3s",
                  top: visible ? "0" : "-100px",
                  zIndex: "99999",
                }}
                className='fixed w-screen'
              >
                <div className='flex flex-row justify-between items-center py-4 px-4'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="152" height="36" viewBox="0 0 192 36" fill="none">
                      <g clip-path="url(#clip0_1_3)">
                          <path d="M0 0.76C2.66 0.34 6.66 0 11.37 0C16.77 0 20.63 0.74 23.28 2.74C25.54 4.46 26.76 6.97 26.76 10.41C26.76 14.79 23.47 17.91 20.72 18.91V19.09C23.06 20.09 24.33 22.33 25.23 25.39C26.29 28.96 27.45 34.08 28.13 35.43H18.5C17.96 34.41 17.05 31.66 16.03 27.33C15.07 23.12 13.78 22.24 10.87 22.24H9.34V35.43H0V0.76ZM9.34 15.5H11.79C15.34 15.5 17.39 13.77 17.39 11.13C17.39 8.49 15.65 7 12.52 6.97C10.75 6.97 9.81 7.06 9.34 7.18V15.5Z" fill="white"/>
                          <path d="M54.29 21.26H41.64V27.74H55.78V35.43H32.21V0.27H55.05V7.97H41.64V13.65H54.29V21.27V21.26Z" fill="white"/>
                          <path d="M61.02 0.76C63.58 0.35 67.27 0 72.63 0C77.7 0 81.32 0.91 83.79 2.8C86.2 4.58 87.8 7.68 87.8 11.27C87.8 14.86 86.61 17.95 84.38 19.94C81.53 22.49 77.41 23.62 72.65 23.62C71.74 23.62 70.93 23.55 70.35 23.51V35.44H61.01V0.76H61.02ZM70.36 16.28C70.87 16.37 71.49 16.41 72.51 16.41C76.18 16.41 78.48 14.52 78.48 11.5C78.48 8.88 76.74 7.1 73.15 7.1C71.77 7.1 70.83 7.21 70.35 7.32V16.28H70.36Z" fill="white"/>
                          <path d="M92.45 0.27H101.88V27.54H115.43V35.43H92.45V0.26V0.27Z" fill="white"/>
                          <path d="M142.09 21.26H129.44V27.74H143.58V35.43H120.01V0.26H142.85V7.96H129.44V13.64H142.09V21.26Z" fill="white"/>
                          <path d="M166.99 35.44L164.39 29.69C163.36 27.45 162.58 25.66 161.67 23.62H161.56C160.94 25.66 160.14 27.48 159.27 29.65L156.93 35.44H146.21L156.12 17.63L146.56 0.27H157.4L159.79 5.87C160.7 7.96 161.37 9.68 162.14 11.67H162.28C163.07 9.38 163.6 7.83 164.33 5.89L166.5 0.27H177.25L167.68 17.44L177.89 35.44H166.98H166.99Z" fill="white"/>
                          <path d="M185.16 2.31C185.51 2.31 186.02 2.34 186.49 2.43C187.23 2.56 187.71 2.78 188.04 3.15C188.31 3.43 188.46 3.82 188.46 4.24C188.46 4.94 188.01 5.33 187.45 5.43V5.45C187.86 5.66 188.11 6.1 188.24 6.67C188.42 7.45 188.54 7.99 188.65 8.21L187.93 8.08C187.84 7.91 187.73 7.43 187.58 6.73C187.42 5.97 187.13 5.63 186.5 5.5L185.84 5.38V7.7L185.15 7.58V2.31H185.16ZM185.85 4.87L186.56 4.99C187.3 5.12 187.77 4.8 187.77 4.19C187.77 3.49 187.27 3.1 186.54 2.97C186.21 2.91 185.97 2.9 185.86 2.91V4.87H185.85Z" fill="white"/>
                          <path d="M186.91 9.95C184.29 9.49 182.16 6.98 182.16 4.36C182.16 1.74 184.29 -0.0200001 186.91 0.45C189.53 0.91 191.66 3.42 191.66 6.04C191.66 8.66 189.53 10.42 186.91 9.96V9.95ZM186.91 1.13C184.67 0.74 182.85 2.24 182.85 4.48C182.85 6.72 184.67 8.86 186.91 9.26C189.15 9.66 190.98 8.15 190.98 5.92C190.98 3.69 189.15 1.54 186.91 1.14V1.13Z" fill="white"/>
                      </g>
                      <defs>
                          <clipPath id="clip0_1_3">
                          <rect width="191.66" height="35.44" fill="white"/>
                          </clipPath>
                      </defs>
                  </svg>
                  <div
                    className='cursor-pointer'
                    onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                  >
                    {/* Hamburger icon (three lines) */}
                    <div className='w-6 h-1 bg-white mb-1'></div>
                    <div className='w-6 h-1 bg-white mb-1'></div>
                    <div className='w-6 h-1 bg-white'></div>
                  </div>
                </div>

                {mobileMenuOpen && (
                  <div className='flex flex-col items-center gap-y-6 py-6'>
                    <a onClick={() => scrollToSection('inicio')} className='text-lg font-bold text-white cursor-pointer hover:text-yellow-300'>
                      INICIO
                    </a>
                    <a onClick={() => scrollToSection('mosquito')} className='text-lg font-bold text-white cursor-pointer hover:text-yellow-300'>
                        MOSQUITO
                    </a>
                    <a onClick={() => scrollToSection('enfermedades')} className='text-lg font-bold text-white cursor-pointer hover:text-yellow-300'>
                        ENFERMEDADES
                    </a>
                    <a onClick={() => scrollToSection('replex')} className='text-lg font-bold text-white cursor-pointer hover:text-yellow-300'>
                        REPLEX
                    </a>
                    <a onClick={() => scrollToSection('deet')} className='text-lg font-bold text-white cursor-pointer hover:text-yellow-300'>
                        DEET
                    </a>
                    <a onClick={() => scrollToSection('pronostico')} className='text-lg font-bold text-white cursor-pointer hover:text-yellow-300'>
                        PRONÓSTICO
                    </a>
                  </div>
                )}
              </div>
          </div>
      )}
    </div>
  )
}

export default Navbar