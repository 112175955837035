import React, { useState, useEffect} from 'react';
import borderLeft from '../assets/borders.png';
import borderRight from '../assets/border-right.png';
import Quiz from '../components/Quiz';
import Mosquito3d from '../components/Mosquito3d';
import Enfermedades from '../components/Enfermedades';
import Products from '../components/Products';
import Deets from '../components/Deets';
import Prognostico from '../components/Prognostico';
import MapMobile from "../components/MapMobile";
import MosquitoMobile from "../components/MosquitoMobile";
import kv from '../assets/kv.png';
import phone from '../assets/phone.png';
import bgImage from '../assets/bg10.png';
import mosquito from '../assets/moquito.gif';
import mosquito2 from '../assets/moquito2.gif';

function Home() {
  const kvStyle = {
    width: '100vw',
    height: '100vh', 
    backgroundImage: `url(${kv})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: 1000,
  };

  const kvPhone = {
    width: '100vw',
    height: '100vh', 
    backgroundImage: `url(${phone})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const bg = {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    height: '100%',
  };

  
     // mobile view
  const [mobile, setMobile] = useState(null);
  const [windowWidth, setWindowWidth] = useState(null);
  const [middleContentHeight, setMiddleContentHeight] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const checkScreen = () => {
    setWindowWidth(window.innerWidth);
    setMobile(window.innerWidth <= 850);
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener('resize', checkScreen);
    checkScreen();

    // Measure the height of the middle content
    if (!mobile) {
      const middleContent = document.getElementById('middle-content');
      if (middleContent) {
        setMiddleContentHeight(middleContent.clientHeight);
      }
    }

    return () => {
      window.removeEventListener('resize', checkScreen);
    };
  }, [mobile]);

  return (
    <div>
      {!mobile && (
        <div className='w-screen flex flex-row justify-between items-start' style={bg}>
          <div className='w-3/12' id="left-div"></div>
          <div id="middle-content" className='w-6/12 flex flex-col justify-start items-center gap-y-24 pb-10'>
            <Quiz />
            <Mosquito3d />
            <Enfermedades />
            <div style={kvStyle} className='flex flex-row justify-between items-center'>
              <img src={mosquito2} alt="GIF Animation" style={{width:"20vw"}}/>
              <img src={mosquito} alt="GIF Animation" style={{width:"30vw"}}/>
            </div>
            <Products />
            <Deets />
            <Prognostico />
          </div>
          <div className='w-3/12' id="right-div"></div>
        </div>
      )}
      {mobile && (
        <div>
          <div className='flex flex-col justify-start items-center gap-y-6'>
            <Quiz />
            <Mosquito3d />
            {/* <MosquitoMobile/> */}
            <Enfermedades />
            <div style={kvPhone} className='flex flex-row justify-between items-end'>
              <img src={mosquito2} alt="GIF Animation" style={{width:"60vw"}}/>
            </div>
            <Products />
            <Deets />
            <MapMobile />
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;