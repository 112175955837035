import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './views/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import MetalPixel from './components/MetaPixel';

function App() {
  return (
    <div>
      <Router>
        {/* <MetalPixel/> */}
        <div>
          <Navbar />
          <div>
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>
          </div>
          <Footer/>
        </div>
      </Router>
    </div>
  );
}

export default App;
