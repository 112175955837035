import React, { useState, useEffect, useRef } from 'react';
import Logo from '../assets/replexlogo.png';
import Resiste from '../assets/resiste.png';
import Title from '../assets/Copy-Head.png';
import productImg from '../assets/Asset 21.png';
import product1 from '../assets/familia.png';
import product2 from '../assets/ninos.png';
import product3 from '../assets/deporte.png';
import product4 from '../assets/ultra.png';
import back1 from '../assets/Asset 30.png';
import back2 from '../assets/Asset 31.png';
import back3 from '../assets/Asset 32.png';
import back4 from '../assets/Asset 33.png';

function Clip({ url, posterUrl  }) {
    return (
      <div className='flex flex-row justify-center items-center'>
      <div className='border-8 border-green-900' style={{ width: '90%' }}>
        <video controls poster={posterUrl} className="object-cover" key={url}>
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    );
  }
  
  function Products() {
    const [currentCard, setCurrentCard] = useState(null);

    const [showBack1, setShowBack1] = useState(false);
    const [showBack2, setShowBack2] = useState(false);
    const [showBack3, setShowBack3] = useState(false);
    const [showBack4, setShowBack4] = useState(false);
    const [currentVideo, setCurrentVideo] = useState('video1');
  
    const videoUrls = {
      video1: 'https://res.cloudinary.com/df0paot5o/video/upload/v1697726040/Replex/byfsfybogp9edytmc9dl.mp4',
      video2: 'https://res.cloudinary.com/df0paot5o/video/upload/v1697726037/Replex/rvysrbr6pnjn3dsu0ayt.mp4',
      video3: 'https://res.cloudinary.com/df0paot5o/video/upload/v1697726040/Replex/w8ieau4ba1csbfougbfl.mp4',
      video4: 'https://res.cloudinary.com/df0paot5o/video/upload/v1697726043/Replex/yutco7plwps5eouzs1ex.mp4',
    };

    const posterUrls = {
      video1: '/product-cover.png',
      video2: '/product2-cover.png',
      video3: '/product3-cover.png',
      video4: '/product4-cover.png',
    };
  
    const handleVideoChange = (videoKey) => {
      setCurrentCard(videoKey);
      setCurrentVideo(videoKey);
    };
  
    //mobile view
    const [mobile, setMobile] = useState(null);
    const [windowWidth, setWindowWidth] = useState(null);
    const [inputValue, setInputValue] = useState(''); 
    const [isOpen, setIsOpen] = useState(false);
  
  
    const checkScreen = () => {
        setWindowWidth(window.innerWidth);
        setMobile(window.innerWidth <= 850);
        setIsOpen(false);
    };
  
    useEffect(() => {
        window.addEventListener('resize', checkScreen);
        checkScreen();
  
        return () => {
            window.removeEventListener('resize', checkScreen);
        };
    }, []);

    return (
      <div id="replex" className="section">
        {!mobile && (
          <div>
            <div className='flex flex-row justify-center items-center gap-x-8 -mt-10 pb-6'>
                <div className='flex flex-col justify-between items-center'>
                    <img src={Logo} alt='Logo' />
                    <img src={Title} alt='Title' style={{ width: '40vh' }} />
                </div>
                <div className='flex flex-col justify-between items-end'>
                    <img src={Resiste} alt='Resiste' />
                    <img src={productImg} alt='Product' style={{ width: '80vh' }} />
                </div>
            </div>
            <div className='flex flex-col justify-center items-center'>
              <div>
                <Clip url={videoUrls[currentVideo]} posterUrl={posterUrls[currentVideo]} />
              </div>
              <div className='flex flex-row justify-center items-start mt-8 gap-x-4' style={{ width: '70vw' }}>
              <img
                  src={currentCard === 'video1' ? back1 : product1}
                  style={{ width: '15vw', cursor: 'pointer' }}
                  onClick={() => handleVideoChange('video1')}
                  alt='Product1'
                />
                <img
                  src={currentCard === 'video2' ? back2 : product2}
                  style={{ width: '15vw', cursor: 'pointer' }}
                  onClick={() => handleVideoChange('video2')}
                  alt='Product2'
                />
                <img
                  src={currentCard === 'video3' ? back3 : product3}
                  style={{ width: '15vw', cursor: 'pointer' }}
                  onClick={() => handleVideoChange('video3')}
                  alt='Product3'
                />
                <img
                  src={currentCard === 'video4' ? back4 : product4}
                  style={{ width: '15vw', cursor: 'pointer' }}
                  onClick={() => handleVideoChange('video4')}
                  alt='Product4'
                />
              </div>
            </div>
          </div>
          )}
          {mobile && (
           <div>
             <div>
              <div className='flex flex-row justify-center items-center gap-x-2 pb-4 px-4'>
                  <div className='flex flex-col justify-between items-center'>
                      <img src={Logo} alt='Logo' />
                      <img src={Title} alt='Title' style={{ width: '40vh' }} />
                  </div>
                  <div className='flex flex-col justify-between items-end'>
                      <img src={Resiste} alt='Resiste' style={{ width: '4vh' }} />
                      <img src={productImg} alt='Product' style={{ width: '80vh' }} />
                  </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <div>
                  <Clip url={videoUrls[currentVideo]} posterUrl={posterUrls[currentVideo]} />
                </div>
                <div className='flex flex-row justify-center items-start mt-4 gap-x-2' style={{ width: '100vw' }}>
                  <img
                    src={currentCard === 'video1' ? back1 : product1}
                    style={{ width: '45vw', cursor: 'pointer' }}
                    onClick={() => handleVideoChange('video1')}
                    alt='Product1'
                  />
                  <img
                    src={currentCard === 'video2' ? back2 : product2}
                    style={{ width: '45vw', cursor: 'pointer' }}
                    onClick={() => handleVideoChange('video2')}
                    alt='Product2'
                  />
                </div>
                <div className='flex flex-row justify-center items-start  gap-x-2' style={{ width: '100vw' }}>
                  <img
                    src={currentCard === 'video3' ? back3 : product3}
                    style={{ width: '45vw', cursor: 'pointer' }}
                    onClick={() => handleVideoChange('video3')}
                    alt='Product3'
                  />
                  <img
                    src={currentCard === 'video4' ? back4 : product4}
                    style={{ width: '45vw', cursor: 'pointer' }}
                    onClick={() => handleVideoChange('video4')}
                    alt='Product4'
                  />
                </div>
              </div>
            </div>
           </div>
          )}
      </div>
    );
  }
  
  export default Products;
