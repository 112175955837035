import React, { useState, useEffect} from 'react';
import Titulo from '../assets/deet.png'
import Formula from "../assets/formula.png"
import placeholder from "../assets/deet-placeholder.png"

function Deets() {
    const url="https://res.cloudinary.com/dqdqz4y5u/video/upload/v1697546688/MABENCA%20PORTFOLIO/midmlpqd5e5zmow6kxxo.mp4"

//mobile view
  const [mobile, setMobile] = useState(null);
  const [windowWidth, setWindowWidth] = useState(null);
  const [inputValue, setInputValue] = useState(''); 
  const [isOpen, setIsOpen] = useState(false);


  const checkScreen = () => {
      setWindowWidth(window.innerWidth);
      setMobile(window.innerWidth <= 850);
      setIsOpen(false);
  };

  useEffect(() => {
      window.addEventListener('resize', checkScreen);
      checkScreen();

      return () => {
          window.removeEventListener('resize', checkScreen);
      };
  }, []);
  
  return (
    <div id="deet" className="section">
        {!mobile && (
            <div className='flex flex-row justify-between items-center gap-x-12 pt-10 -mt-16' style={{width:"63vw"}}>
                <div className='w-2/3 flex flex-col justify-start items-center gap-y-8'>
                    <img src={Titulo}/>
                    <img src={Formula}/>
                    <p className='enfermedades'>es el nombre común del N,N-diethil-m-toluamida. Se ha utilizado como repelente de insectos efectivo desde que fue desarrollado por el Ejército de los Estados Unidos en 1946.
                    <br/>
                    <br/>
                    Es un aceite destinado a aplicarse sobre la piel o la ropa que brinda protección contra mosquitos y muchos insectos que pican.
                    <br/>
                    <br/>
                    La DEET interfiere con las neuronas y los receptores en las antenas y la boca de los mosquitos que detectan sustancias químicas como ácido láctico y dióxido de carbono.
                    </p>
                </div>
                <div >
                    <video controls  className='rounded-3xl border-8 border-yellow-300'>
                        <source src={url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        )}
        {mobile && (
            <div>
                <div className='flex flex-col justify-center items-center gap-x-12 pt-16 -mt-16'>
                    <div className='flex flex-col justify-center items-center gap-y-8 px-2'>
                        <img src={Titulo}/>
                        <img src={Formula} style={{width:"60vw"}}/>
                        <p className='enfermedades text-center'>es el nombre común del N,N-diethil-m-toluamida. Se ha utilizado como repelente de insectos efectivo desde que fue desarrollado por el Ejército de los Estados Unidos en 1946.</p>
                        <p className='enfermedades text-center'>Es un aceite destinado a aplicarse sobre la piel o la ropa que brinda protección contra mosquitos y muchos insectos que pican.</p>
                        <p className='enfermedades text-center'>La DEET interfiere con las neuronas y los receptores en las antenas y la boca de los mosquitos que detectan sustancias químicas como ácido láctico y dióxido de carbono.</p>
                    </div>
                    <div className='pt-10 px-2'>
                        <video controls poster={placeholder} className='rounded-3xl border-8 border-yellow-300 object-cover'>
                            <source src={url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        )}
    </div>
  )
}

export default Deets